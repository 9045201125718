import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PlayAndThink from "../views/PlayAndThink.vue";
import PlayAndThinkDetails from "../views/PlayAndThinkDetails.vue";
import Games from "../views/Games.vue";
import Puzzle from "../views/Puzzle.vue";
import Hangeul from "../views/Hangeul.vue";
import HangeulDetails from "../views/HangeulDetails.vue";
import Parents from "../views/Parents.vue";
import VideosForParents from "../views/VideosForParents.vue";
import Signup from "../views/Signup.vue";
import Login from "../views/Login.vue";
import ClassroomEnglish from "../views/ClassroomEnglish.vue";
import ClassroomEnglishDetails from "../views/ClassroomEnglishDetails.vue";
import Admin from "../views/Admin.vue";
import { projectAuth } from "../firebase/config";

Vue.use(VueRouter);

// auth guard
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  console.log("current user in auth guard: ", user);

  const adminUsers = [
    "8YOECctuODW6a1e0KhfD6oojJZF2", // 황의경 iwonder3@daum.net 12345678
    "OdtSSGHfYgUDW3IABxzoaElxwEB2", // 조천희 cchonhee@daum.net test1234
  ];

  if (!adminUsers.includes(user.uid)) {
    next({ name: "Home" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/puzzle",
    name: "Puzzle",
    component: Puzzle,
    props: true,
    // beforeEnter: requireAuth,
  },
  {
    path: "/play-and-think",
    name: "PlayAndThink",
    component: PlayAndThink,
    // beforeEnter: requireAuth,
  },
  {
    path: "/play-and-think-details/:level_volume",
    name: "PlayAndThinkDetails",
    component: PlayAndThinkDetails,
    props: true,
    // beforeEnter: requireAuth,
  },
  {
    path: "/games",
    name: "Games",
    component: Games,
    props: true,
    // beforeEnter: requireAuth,
  },
  {
    path: "/hangeul",
    name: "Hangeul",
    component: Hangeul,
    // beforeEnter: requireAuth,
  },
  {
    path: "/hangeul-details/:level_volume",
    name: "HangeulDetails",
    component: HangeulDetails,
    // beforeEnter: requireAuth,
  },
  {
    path: "/parents",
    name: "Parents",
    component: Parents,
    // beforeEnter: requireAuth,
  },
  {
    path: "/videos-for-parents/:level_volume",
    name: "VideosForParents",
    component: VideosForParents,
    props: true,
    // beforeEnter: requireAuth,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/classroom-english",
    name: "ClassroomEnglish",
    component: ClassroomEnglish,
    // beforeEnter: requireAuth,
  },
  {
    path: "/classroom-enlsih/:chapter",
    name: "ClassroomEnglishDetails",
    component: ClassroomEnglishDetails,
    // beforeEnter: requireAuth,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    beforeEnter: requireAuth,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
